<template>
  <div
    class="bussinessField"
    v-loading="loading"
    element-loading-text="加载中，请稍候"
    element-loading-background="rgba(255,255,255,.9)"
    v-if="!loading"
  >
    <pageTitle pageTitle="直播自定义字段">
      <div slot="content">
        创建直播时，将展示添加的直播自定义字段；凭借vhall数据连接器，可将直播与您的业务系统字段绑定，进行项目评估、ROI核算等
      </div>
    </pageTitle>
    <!-- 操作栏  -->
    <div class="operaBox">
      <vh-button
        type="primary"
        round
        @click="create()"
        v-preventReClick
        size="medium"
        class="length104"
      >
        创建自定义字段
      </vh-button>
      <vh-button
        size="medium"
        round
        @click="remove"
        plain
        :disabled="!checkList.length"
        class="transparent-btn"
        v-preventReClick
        v-if="false"
      >
        批量删除
      </vh-button>
      <div class="searchBox" v-if="false">
        <vh-input
          class="search-tag"
          placeholder="请输入字段名称"
          v-model="searchName"
          clearable
          round
          @change="searchHandler()"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix"></i>
        </vh-input>
      </div>
    </div>
    <div class="content-list">
      <vh-table
        v-if="totalNum !== 0"
        ref="tableList"
        :data="tableList"
        @selection-change="handleTableCheckbox"
        max-height="auto"
        :row-key="
          row => {
            return row[this.rowKey];
          }
        "
      >
        <div slot="empty" style="height: 0"></div>
        <vh-table-column
          v-if="false"
          type="selection"
          width="60"
          align="left"
          :reserve-selection="!!rowKey"
        />
        <vh-table-column prop="field_name" label="字段名称"></vh-table-column>
        <vh-table-column prop="field_key" label="字段标识"></vh-table-column>
        <vh-table-column prop="created_at" label="创建时间" width="250"></vh-table-column>
        <vh-table-column prop="is_required" label="是否必填" width="100">
          <template slot-scope="{ row }">
            <span>{{ row.is_required == '1' ? '是' : '否' }}</span>
          </template>
        </vh-table-column>
        <vh-table-column label="操作" width="110" align="left">
          <template slot-scope="scope">
            <vh-button type="text" @click="handleEdit(scope.$index, scope.row)">编辑</vh-button>
            <vh-button type="text" @click="handleDelete(scope.$index, scope.row)">删除</vh-button>
          </template>
        </vh-table-column>
      </vh-table>

      <null-page text="暂未创建自定义字段" nullType="button" v-if="totalNum === 0"></null-page>
    </div>

    <!-- 创建标签弹框 -->
    <vh-dialog :title="title" :visible.sync="createDialog" width="650px">
      <vh-form :model="customFieldForm" :rules="rules" ref="customForm" label-width="80px">
        <vh-form-item label="字段名称" prop="field_name">
          <vh-input
            placeholder="建议输入字段业务名称，将展示至创建直播页"
            v-model="customFieldForm.field_name"
            show-word-limit
            :maxlength="6"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="字段标识" prop="field_key">
          <vh-input
            placeholder="数据库字段标识，通过该值索引计算，仅支持字母、数字与下划线"
            v-model="customFieldForm.field_key"
            show-word-limit
            :maxlength="32"
            :disabled="field_key_disabled"
          ></vh-input>
        </vh-form-item>

        <vh-form-item label="是否必填" prop="is_required">
          <vh-radio-group v-model="customFieldForm.is_required">
            <vh-radio :label="'1'">必填</vh-radio>
            <vh-radio :label="'0'">选填</vh-radio>
          </vh-radio-group>
        </vh-form-item>
      </vh-form>

      <div slot="footer" class="dialog-footer">
        <vh-button size="medium" @click="unSureAsyncHandle" plain round>取 消</vh-button>
        <vh-button
          type="primary"
          size="medium"
          v-preventReClick
          @click="submitForm('customForm')"
          round
        >
          确 定
        </vh-button>
      </div>
    </vh-dialog>
  </div>
</template>

<script>
  import regRule from '@/utils/reg-rule.js';
  import PageTitle from '@/components/PageTitle';
  import NullPage from '@/views/PlatformModule/Error/nullPage.vue';
  export default {
    data() {
      const validate_field_key = (rule, value, callback) => {
        if (value == '') {
          callback(new Error('请输入字段标识'));
        } else {
          if (!regRule.alphanumericWithUnderscore.test(value)) {
            callback(new Error('数据库字段值，通过该值索引计算，仅支持字母、数字与下划线'));
          } else {
            callback();
          }
        }
      };

      return {
        loading: false,
        tableList: [],
        rowKey: '', //复选框选择
        flag: false, //复选框选择
        totalNum: 0, //总页码
        searchName: '',
        checkList: [],
        createDialog: false,
        status: 'new', // 操作状态
        title: '', // 编辑窗口标题
        field_key_disabled: false,
        customFieldForm: {
          field_key: '',
          field_name: '', // 自定义字段名称
          is_required: '1' // 是否必填, 默认必填,
        },
        rules: {
          field_name: [{ required: true, message: '请输入字段名称', trigger: 'blur' }],
          field_key: [{ required: true, validator: validate_field_key, trigger: 'blur' }]
        }
      };
    },
    components: {
      PageTitle,
      NullPage
    },
    mounted() {
      // 查询列表接口
      this.searchHandler();
    },
    methods: {
      // 创建标签
      create() {
        if (this.totalNum >= 5) {
          this.$vhMessage({
            message: '最多可以创建5个自定义字段',
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
        } else {
          this.createDialog = true;
          this.status = 'new';
          this.field_key_disabled = false;
          this.title = '创建';
          this.resetFieldForm();
          this.$refs['customForm'].clearValidate();
        }
      },
      // 搜索
      searchHandler(row) {
        this.$fetch('accountWebinarCustomFieldsList', {}).then(res => {
          if (res.code == 200) {
            this.tableList = res.data.list;

            this.totalNum = this.tableList.length;
          }
        });
      },
      // 复选框操作
      handleTableCheckbox(val) {
        //锁，避免toggleRowSelection被动触发selected-change
        if (this.flag) {
          return;
        }
        if (this.rowKey && val.length > 200) {
          this.flag = true;
          // 截取20位之后的数组  禁止选中
          let tempArr = val.slice(200);
          // 截取前20位
          val = val.slice(0, 200);
          if (tempArr.length !== 0) {
            tempArr.forEach(ele => {
              this.$refs.tableList.toggleRowSelection(ele, false);
            });
          }
          this.$vhMessage.error('最多一次性选中200条数据');
          this.flag = false;
        }

        this.checkList = val;
      },
      // 编辑前获取详情
      handleEdit(index, row) {
        this.customFieldForm.field_key = row.field_key;
        this.customFieldForm.field_name = row.field_name;
        this.customFieldForm.is_required = row.is_required;
        console.log('handleEdit------->', index, row);
        this.createDialog = true;
        this.status = 'edit';
        this.field_key_disabled = true;
        this.title = '编辑';
        this.$nextTick(() => {
          this.$refs['customForm']?.clearValidate();
        });
      },
      // 删除
      handleDelete(index, row) {
        console.log('handleDelete--------》', row);
        this.$vhConfirm(
          '当前自定义字段已添加至直播，删除后直播字段将同步删除，是否确认删除？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            lockScroll: false,
            closeOnClickModal: false,
            roundButton: true,
            closeOnPressEscape: false,
            confirmButtonPosition: 'left'
          }
        ).then(() => {
          this.$fetch('accountWebinarCustomFieldsDelete', {
            field_key: row.field_key
          })
            .then(res => {
              if (res.code == 200) {
                this.searchHandler();
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
              }
            })
            .catch(err => {
              this.$vhMessage({
                message: err.msg,
                showClose: true,
                type: 'warning',
                customClass: 'zdy-info-box'
              });
            });
        });
      },
      // 批量删除
      remove() {
        this.deleteHandle(this, false, this.checkList);
      },
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.sureAsyncHandle();
          } else {
            return false;
          }
        });
      },
      // 创建保存
      sureAsyncHandle() {
        let api = '';
        let params = {
          field_key: this.customFieldForm.field_key,
          field_name: this.customFieldForm.field_name,
          is_required: this.customFieldForm.is_required
        };
        let tip;
        if (this.status == 'new') {
          api = 'accountWebinarCustomFieldsCreate';
          tip = '创建成功';
        } else {
          api = 'accountWebinarCustomFieldsEdit';
          tip = '编辑成功';
        }
        this.$fetch(api, params)
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage.success(tip);
              this.unSureAsyncHandle();
              this.searchHandler();
            }
          })
          .catch(err => {
            this.$vhMessage.warning(err.msg);
          });
      },
      // 创建取消
      unSureAsyncHandle() {
        this.createDialog = false;
      },
      // 重置表单数据为默认数据
      resetFieldForm() {
        this.customFieldForm.field_key = '';
        this.customFieldForm.field_name = '';
        this.customFieldForm.is_required = '1';
      }
    }
  };
</script>
<style lang="less" scope>
  .bussinessField {
    .pageTitle {
      color: #1a1a1a;
      font-size: 22px;
      font-weight: 600;
    }
    .operaBox {
      overflow: hidden;
      margin-top: 12px;
      margin-bottom: 20px;
      .searchBox {
        float: right;
      }
    }
    .content-list {
      width: 100%;
      .layout--right--main();
      .padding-table-list();
    }
  }
</style>
